import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import duo from '../imgs/wilko_simon.jpeg';
import { ImgText, Row, Column, Hr, H1 } from '../components/Layout';
import Seo from '../components/Seo';
export const _frontmatter = {
  "title": "wearenaice",
  "path": "/weareniace"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Seo title="We are NAICE" description="Contact, Team, About us" mdxType="Seo" />
    <H1 my="30px" mdxType="H1">We are NAICE</H1>
    <ImgText {...{
      img_path: duo
    }} mdxType="ImgText">
      <p>NAICE Architects is a young, interdisciplinary team of architects and creative minds in Berlin. Founded by Simon Schroedter and Wilko Hoffmann, the firm focuses on architecture, design, and installations.</p>
      <p>Projects have included art and spatial installations as well as classical new construction, expansions, the renovation of landmarked buildings, and innovative spatial concepts for shops and medical practices. Completed projects in Berlin include multi-story residential housing, flagship stores, a dental surgery practice, a psychotherapist’s office, a café with art exhibitions, bakery shops, various installations for fairs and events, and top-notch residences.</p>
    </ImgText>
    <Hr mdxType="Hr" />
    <h2>{`Contact`}</h2>
    <Row mdxType="Row">
      <Column maxWidth="400px" mdxType="Column">
        <p><strong>NAICE architects</strong></p>
        <p>Cranachstraße 54 </p>
        <p>12157 Berlin </p>
        <p>Germany </p>
      </Column>  
      <Column width="400px" mdxType="Column">  
        <p><strong>Email:</strong>   info@naice.one  </p>
        <p><strong>Phone:</strong> +49 (0) 30-1205356-10</p>
      </Column>  
    </Row>
    <Hr mdxType="Hr" />
    <h2>{`Team since 2018`}</h2>
    <Row mdxType="Row">
      <Column mdxType="Column">
        <p><strong>Simon Schroedter</strong> (b. Kassner, Architect, Founder)</p>
        <p><strong>Wilko Hoffmann</strong> (Architect, Founder)</p>
        <p><strong>Niki Massourides</strong> (Project Architect)</p>
        <p><strong>Yannick-Johannes Tiedt</strong> (Project Architect)</p>
        <p><strong>Somya Goyal</strong> (Project Architect)</p>
        <p><strong>Nagehan Taviloglu</strong> (Architect)</p>
        <p><strong>Berran Kalkan</strong> (Project Architect)</p>
        <p><strong>Elena Ballendat</strong> (Project Architect)</p>
        <p><strong>Yasemin Pamir</strong> (Project Architect)</p>
        <p><strong>Anna Schmitz</strong> (Working Student)</p>
        <p><strong>Carlo Landini</strong> (Senior Architect)</p>
        <p><strong>Ralf Ewest</strong> (Project Architect)</p>
        <p><strong>Tareq Almuhammad</strong> (Junior Architect)</p>
        <p><strong>Frieder Vogler</strong> (Junior Architect)</p>
        <p><strong>Dovesh Bundhoo</strong> (Working Student)</p>
        <p><strong>Jose Manuel Rodríguez López</strong> (Junior Architect)</p>
        <p><strong>Abril Monserrat Alfaro</strong> (Project Architect)</p>
        <p><strong>Ashutosh Sharma</strong> (Intern)</p>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      